<template>
    <div id="IPQCChartthree" style="height: 100%; width: 100%;"></div>
</template>

<script>
export default {
  data () {
    return {
      option: {
        title: {
          text: this.$t('All.系统统计日期范围最终结果数量（状态）'),
          left: 'center'
          // top: '2%'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '2%',
          containLabel: true
        },
        label: {
          show: true,
          formatter: '{b}({d}%)'
        },
        series: [
          {
            name: this.$t('All.系统统计日期范围最终结果数量（状态）'),
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: '暂存' },
              { value: 735, name: '允收' },
              { value: 580, name: '待审' },
              { value: 484, name: '紧急结案' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      myChart: null,
      cq: '',
      state: '',
      rq: '',
      cs: ''
    }
  },
  mounted () {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()

    // 当前月份的第一天
    const monthStart = this.formatDate(new Date(year, month, 1))

    // 当前月份的最后一天
    const monthEnd = this.formatDate(new Date(year, month + 1, 0))
    this.Getdata(this.$store.state.Login.Factory === 'All' ? 'TC' : this.$store.state.Login.Factory, monthStart, monthEnd)
    window.addEventListener('resize', this.resizechart)
  },
  created () {
  },
  methods: {
    resizechart () {
      if (this.myChart !== null && this.myChart !== undefined) {
        this.myChart.resize()
      }
    },
    // 格式化函数，确保月份和日期为两位数
    formatDate (date) {
      const y = date.getFullYear()
      const m = (date.getMonth() + 1).toString().padStart(2, '0') // 月份加 1，确保两位数
      const d = date.getDate().toString().padStart(2, '0') // 确保日期是两位数
      return `${y}-${m}-${d}`
    },
    async Getdata (cq, monthStart, monthEnd, user) {
      try {
        this.myChart = this.$echarts.init(document.getElementById('IPQCChartthree'))
        this.myChart.resize()
        this.myChart.clear()
        const { data: res } = await this.$http.post(`api/Newiqc/Getiqcallstate?Factory=${cq}&Type=IPQC&Start=${monthStart}&End=${monthEnd}&User=${user === undefined ? '' : user}`)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.option.series[0].data = res.response.data
        // 遍历 series 数据并格式化 name
        this.option.series[0].data.forEach(item => {
          item.name = this.$PublicJs.Result(parseInt(item.name))
        })
        this.myChart.setOption(this.option, true)
      } catch (error) {
        return this.$message.error(error)
      }
    }
  }
}
</script>
